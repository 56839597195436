import {z} from "zod";

export const BodyworkType = z.ZodEnum.create(['SEDAN', 'CONVERTIBLE', 'COUPE', 'WAGON', 'VAN', 'MPV', 'SUV', 'HATCHBACK']);
export type BodyworkType = z.infer<typeof BodyworkType>;

export const bodyworkOptions: Record<BodyworkType, string> = {
    SEDAN: 'Berlina',
    CONVERTIBLE: 'Cabrio',
    COUPE: 'Coupé',
    WAGON: 'Familiar',
    VAN: 'Industrial',
    MPV: 'Monovolumen',
    SUV: 'Todoterreno',
    HATCHBACK: 'Utilitario',
};