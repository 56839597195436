import { ApiClientError } from '@/lib/client/ApiClientError';
import { objectToQueryString } from '@/lib/utils/queryParams';
import {
    ApiGetPreOfferResponse,
    AppraisalsError,
    Asnef,
    AsnefProfiles,
    DealFilters,
    DeliveryDetails,
    EditDealNoteRequest,
    FinancialAggregation,
    GetAlertsResponse,
    GetAppraisalsResponse,
    GetAttachmentDownloadUrlResponse,
    GetAttachmentPressignedUrlRequest,
    GetAttachmentRequest,
    GetAttachmentResponse,
    GetAttachmentUploadUrlResponse,
    GetCarfaxResponse,
    GetDealBasicInfo,
    GetDealDocumentationLinkResponse,
    GetDealListResponse,
    GetDealSubmissionList,
    GetFinancialAggregationsResponse,
    GetNotesResponse,
    GetSingleNoteResponse,
    GetUserFilters,
    GetUserResponse,
    GetVehicleDetailsResponse,
    NoteFilters,
    Paginated,
    PaginatedSchema,
    PatchDealRequest,
    PatchFinancialAggregation,
    PatchUserRequest,
    PostDealParams,
    PostDealResponse,
    PutDealAssignedAgentRequest,
    PutDealAssignedAgentResponse,
    PutDealDocumentationLinkRequest,
    PutDealSituationRequest,
    PutDealSituationResponse,
    PutDealVehicleLicensePlateRequest,
    ReservationsFilters,
    ReservationsList,
    Stage,
    User,
} from '@/types';
import { ChatListFilters, ChatMessage, ClientDetails } from '@/types/Client';
import { ClientList, ClientListFilters } from '@/types/Client/list';
import {
    GetDealAddressesResponse,
    GetDealBankDetailResponse,
    GetDealCatalogueVehicle,
    GetDealCoownerDetailResponse,
    GetDealEmploymentResponse,
    GetDealForeignDetailResponse,
    GetDealPersonalDetailsResponse,
    GetDealVehicleReponse,
    GetMktDetailsResponse,
    PutDealAddressesRequest,
    PutDealBankDetailRequest,
    PutDealCoownerDetailRequest,
    PutDealEmploymentRequest,
    PutDealForeignDetailResponse,
    PutDealPersonalDetailsRequest,
    PutDealVehicleRequest,
    PutDealVehicleResponse,
    Vehicle,
} from '@/types/Deal';
import {
    BulkUpdateCsvSuccessesResponse,
    BulkUpdateCsvUpdatesResponse,
    CatalogueVehicleFilters,
    CatalogueVehicleManagedAd,
    CatalogueVehicleRepricing,
    CatalogueVehicleTransitionHistory,
    CatalogueVehicleTransitionHistoryRecord,
    ChangeCatalogueVehicleStatusProps,
    GetCatalogueVehicleDetails,
    GetCatalogueVehicleRepricing,
    GetCatalogueVehiclesListResponse,
    GetVehicleBasicInfoResponse,
    GetVehicleExtrasResponse,
    GetVehiclePerformanceResponse,
    GetVehiclePriceResponse,
    GetVehicleStatusResponse,
    PostCatalogueVehicle,
    PostCatalogueVehicleManagedAd,
    PostCatalogueVehicleRepricing,
    PutCatalogueVehicleDetails,
    CatalogueVehicleMissingFieldsToPublishResponse,
} from '@/types/Vehicle';
import { CalculatedAgreedPrice } from '@/types/Vehicle/agreedPrices';
import {
    GetVehicleFeatureResponse,
    PostFeatureResponse,
    PostVeicleModelFeature,
    PostVeicleVersionFeature,
} from '@/types/Vehicle/features';
import { GenericNote, GetGenericNotesResponse, PostGenericNoteRequest } from '@/types/Vehicle/notes';
import { GetCatalogueVehiclePublicationDetails } from '@/types/Vehicle/publication';
import { GetNotificationHistoryListResponse, NotificationHistoryFilters } from '@/types/Vehicle/repricingHistory';
import {
    DealAmountBreakdownMetrics,
    DealDiscount,
    DealTransaction,
    GetDealAmountBreakdown,
    PatchDealAmountBreakdown,
    PutDealAmountBreakdownDiscount,
    PutDealAmountBreakdownTransaction,
} from '@/types/amountBreakdown';
import { GetConfigurationResponse, PutConfigurationRequest } from '@/types/configurations';
import { GetOfferItemResponse, GetOffersResponse, PostOfferRequest, PostOfferResponse } from '@/types/offer';
import { GetOperationsListResponse, OperationFilters } from '@/types/operations';
import { GetProvinceResponse } from '@/types/provinces';
import { PutRentTrackingDetails, RentTrackingDetails, VehicleInspection } from '@/types/renting';
import { DealReservationResponse, PostDealReservationRequest } from '@/types/reservation';
import { GetStatisticsResponse, StatisticsFilters } from '@/types/statistics';
import {
    ChangeSubmissionsStatusProps,
    GetSubmissionDetails,
    PatchSubmissionOfferRequest,
    TransitionHistory,
    TransitionHistoryRecord,
} from '@/types/submission';
import { CreateClientFormData } from '@/views/clients/create/forms/CreateClientFormSchema';
import { DocumentsFormData } from '@/views/clients/details/documents/forms/DocumentsSchema';
import { BankDetailsFormData } from '@/views/clients/details/info/forms/BankDetailsFormSchema';
import { BillingAddressFormData } from '@/views/clients/details/info/forms/BillingAddressFormSchema';
import { CoOwnerFormData } from '@/views/clients/details/info/forms/CoOwnerFormSchema';
import { EmploymentFormData } from '@/views/clients/details/info/forms/EmploymentFormSchema';
import { PersonalInformationFormData } from '@/views/clients/details/info/forms/PersonalInformationFormSchema';
import { z } from 'zod';

async function _fetch<T extends z.ZodTypeAny>(
    input: RequestInfo,
    init: RequestInit,
    type: T,
    options: { returnRawResponse: boolean } = { returnRawResponse: false },
): Promise<z.infer<T>> {
    const headers = new Headers(init?.headers);
    const res = await fetch(input, { ...init, headers });

    if (options.returnRawResponse) {
        return res;
    }

    const data = await res.json();

    if (!res.ok) {
        throw new ApiClientError(data.message, res.status, data);
    }

    const result = await type.safeParseAsync(data);

    if (!result.success) {
        throw new ApiClientError(result.error.toString(), 422);
    }

    return result.data;
}

function DealsRoutes() {
    async function getDeals(filters?: DealFilters): Promise<GetDealListResponse> {
        let uri = '/api/deals';
        if (filters) {
            uri += `?${objectToQueryString({
                ...filters,
                statuses: ['REJECTED', 'SALES'],
            })}`;
        }
        return await _fetch(uri, { method: 'GET' }, GetDealListResponse);
    }

    async function getDealsCsv(filters?: Partial<DealFilters>): Promise<any> {
        let uri = '/api/deals';
        if (filters) {
            uri += `?${objectToQueryString({
                ...filters,
                statuses: ['REJECTED', 'SALES'],
            })}`;
        }
        return await _fetch(
            uri,
            {
                method: 'GET',
                headers: {
                    accept: 'text/csv',
                },
            },
            z.unknown(),
            { returnRawResponse: true },
        );
    }

    async function postDeal(data: PostDealParams): Promise<PostDealResponse> {
        return await _fetch(`/api/deals`, { method: 'POST', body: JSON.stringify(data) }, PostDealResponse);
    }

    async function getDealCarfax(uuid: string): Promise<GetCarfaxResponse> {
        return await _fetch(`/api/deals/${uuid}/carfax`, { method: 'GET' }, GetCarfaxResponse);
    }

    async function getDealAlerts(uuid: string): Promise<GetAlertsResponse> {
        return await _fetch(`/api/deals/${uuid}/alerts`, { method: 'GET' }, GetAlertsResponse);
    }

    async function getAsnef(uuid: string, profile: AsnefProfiles): Promise<Asnef> {
        return await _fetch(`/api/deals/${uuid}/asnef/${profile}`, { method: 'GET' }, Asnef);
    }

    async function postAsnef(uuid: string, profile: AsnefProfiles): Promise<Asnef> {
        return await _fetch(`/api/deals/${uuid}/asnef/${profile}`, { method: 'POST' }, Asnef);
    }

    async function getDealBasicInfo(uuid: string): Promise<GetDealBasicInfo> {
        return await _fetch(`/api/deals/${uuid}/basic-info`, { method: 'GET' }, GetDealBasicInfo);
    }

    async function postBulkAppraisalCsv(csv: File): Promise<BulkUpdateCsvUpdatesResponse> {
        const formData = new FormData();
        formData.append('bulkAppraisalCSV', csv, csv.name);

        return await _fetch(
            `/api/catalogue-vehicles/bulk-appraisal`,
            {
                method: 'POST',
                body: formData,
            },
            BulkUpdateCsvUpdatesResponse,
        );
    }

    async function getDealAppraisals(uuid: string): Promise<GetAppraisalsResponse | AppraisalsError> {
        return await _fetch(
            `/api/deals/${uuid}/appraisals`,
            { method: 'GET' },
            GetAppraisalsResponse.or(AppraisalsError),
        );
    }

    async function getDealPreOffer(uuid: string): Promise<ApiGetPreOfferResponse> {
        return await _fetch(`/api/deals/${uuid}/pre-offer`, { method: 'GET' }, ApiGetPreOfferResponse);
    }

    async function getDealFinancialAggregations(uuid: string): Promise<GetFinancialAggregationsResponse> {
        return await _fetch(
            `/api/deals/${uuid}/financial-aggregations`,
            { method: 'GET' },
            GetFinancialAggregationsResponse,
        );
    }

    async function postDealFinancialAggregation(uuid: string): Promise<FinancialAggregation> {
        return await _fetch(`/api/deals/${uuid}/financial-aggregations`, { method: 'POST' }, FinancialAggregation);
    }

    async function patchDealFinancialAggregation(
        dealUuid: string,
        aggregationUuid: string,
        data: PatchFinancialAggregation,
    ): Promise<FinancialAggregation> {
        return await _fetch(
            `/api/deals/${dealUuid}/financial-aggregations/${aggregationUuid}`,
            { method: 'PATCH', body: JSON.stringify(data) },
            FinancialAggregation,
        );
    }

    async function updateDealSituation(uuid: string, data: PutDealSituationRequest) {
        return await _fetch(
            `/api/deals/${uuid}/situation`,
            { method: 'PUT', body: JSON.stringify(data) },
            PutDealSituationResponse,
        );
    }

    async function updateDealStage(uuid: string, data: { stage: Stage }) {
        return await _fetch(
            `/api/deals/${uuid}/stage`,
            { method: 'PUT', body: JSON.stringify(data) },
            z.object({ stage: Stage }),
        );
    }

    async function putDealAssignedAgents(dealUuid: string, data: PutDealAssignedAgentRequest) {
        return await _fetch(
            `/api/deals/${dealUuid}/agents`,
            { method: 'PUT', body: JSON.stringify(data) },
            PutDealAssignedAgentResponse,
        );
    }

    async function patchDeal(dealUuid: string, data: PatchDealRequest) {
        return await _fetch(`/api/deals/${dealUuid}`, { method: 'PATCH', body: JSON.stringify(data) }, z.object({}));
    }

    async function getDealDocumentationLink(dealUuid: string): Promise<GetDealDocumentationLinkResponse> {
        return await _fetch(`/api/deals/${dealUuid}/documentation`, { method: 'GET' }, PutDealDocumentationLinkRequest);
    }

    async function putDealDocumentationLink(
        dealUuid: string,
        data: PutDealDocumentationLinkRequest,
    ): Promise<GetDealDocumentationLinkResponse> {
        return await _fetch(
            `/api/deals/${dealUuid}/documentation`,
            { method: 'PUT', body: JSON.stringify(data) },
            PutDealDocumentationLinkRequest,
        );
    }

    async function getDealDeliveryDetails(dealUuid: string): Promise<DeliveryDetails> {
        return await _fetch(`/api/deals/${dealUuid}/delivery`, { method: 'GET' }, DeliveryDetails);
    }

    async function putDealDeliveryDetails(dealUuid: string, data: DeliveryDetails): Promise<DeliveryDetails> {
        return await _fetch(
            `/api/deals/${dealUuid}/delivery`,
            { method: 'PUT', body: JSON.stringify(data) },
            DeliveryDetails,
        );
    }

    async function patchDealCatalogueVehicle(
        dealUuid: string,
        data: PatchDealAmountBreakdown,
    ): Promise<PatchDealAmountBreakdown> {
        return await _fetch(
            `/api/deals/${dealUuid}/catalogue-vehicle`,
            { method: 'PATCH', body: JSON.stringify(data) },
            PatchDealAmountBreakdown,
        );
    }

    async function getDealCatalogueVehicle(dealUuid: string): Promise<GetDealCatalogueVehicle> {
        return await _fetch(`/api/deals/${dealUuid}/catalogue-vehicle`, { method: 'GET' }, GetDealCatalogueVehicle);
    }

    async function putDealVehicleLicensePlate(dealUuid: string, data: PutDealVehicleLicensePlateRequest) {
        return await _fetch(
            `/api/deals/${dealUuid}/vehicle/license-plate`,
            { method: 'PUT', body: JSON.stringify(data) },
            GetDealVehicleReponse,
        );
    }

    return {
        getDeals,
        getDealsCsv,
        postDeal,
        getDealBasicInfo,
        postBulkAppraisalCsv,
        getDealAppraisals,
        getDealPreOffer,
        getDealFinancialAggregations,
        postDealFinancialAggregation,
        patchDealFinancialAggregation,
        getDealCarfax,
        getDealAlerts,
        getAsnef,
        postAsnef,
        updateDealSituation,
        updateDealStage,
        putDealAssignedAgents,
        getDealDocumentationLink,
        putDealDocumentationLink,
        getDealDeliveryDetails,
        putDealDeliveryDetails,
        patchDeal,
        patchDealCatalogueVehicle,
        getDealCatalogueVehicle,
        putDealVehicleLicensePlate,
    };
}

function DealNotesRoutes() {
    async function getDealNotes(uuid: string, filters?: NoteFilters): Promise<GetNotesResponse> {
        let uri = `/api/deals/${uuid}/notes`;
        uri += filters ? `?${objectToQueryString(filters)}` : '';
        return await _fetch(uri, { method: 'GET' }, GetNotesResponse);
    }

    async function getPinnedDealNotes(uuid: string, filters?: NoteFilters): Promise<GetNotesResponse> {
        let uri = `/api/deals/${uuid}/pinned-notes`;
        uri += filters ? `?${objectToQueryString(filters)}` : '';
        return await _fetch(uri, { method: 'GET' }, GetNotesResponse);
    }

    async function postDealNotes(uuid: string, data: EditDealNoteRequest): Promise<GetSingleNoteResponse> {
        return await _fetch(
            `/api/deals/${uuid}/notes`,
            { method: 'POST', body: JSON.stringify(data) },
            GetSingleNoteResponse,
        );
    }

    async function putNotePinned(uuid: string, noteUuid: string, isPinned: boolean): Promise<GetSingleNoteResponse> {
        return await _fetch(
            `/api/deals/${uuid}/notes/${noteUuid}/is-pinned`,
            { method: 'PUT', body: JSON.stringify({ isPinned: isPinned }) },
            GetSingleNoteResponse,
        );
    }

    async function patchDealNotes(
        dealUuid: string,
        noteUuid: string,
        data: EditDealNoteRequest,
    ): Promise<GetSingleNoteResponse> {
        return await _fetch(
            `/api/deals/${dealUuid}/notes/${noteUuid}`,
            { method: 'PATCH', body: JSON.stringify(data) },
            GetSingleNoteResponse,
        );
    }

    async function deleteNote(dealUuid: string, noteUuid: string) {
        return await _fetch(`/api/deals/${dealUuid}/notes/${noteUuid}`, { method: 'DELETE' }, z.object({}));
    }

    async function getAtachmentUploadUrl(
        uuid: string,
        noteUuid: string,
        data: GetAttachmentPressignedUrlRequest,
    ): Promise<GetAttachmentUploadUrlResponse> {
        return await _fetch(
            `/api/deals/${uuid}/notes/${noteUuid}/attachment-upload-url?${objectToQueryString(data)}`,
            { method: 'GET' },
            GetAttachmentUploadUrlResponse,
        );
    }

    async function postAttachment(
        uuid: string,
        noteUuid: string,
        data: GetAttachmentRequest,
    ): Promise<GetAttachmentResponse> {
        return await _fetch(
            `/api/deals/${uuid}/notes/${noteUuid}/attachments`,
            { method: 'POST', body: JSON.stringify(data) },
            GetAttachmentResponse,
        );
    }

    async function getAttachmentDownloadUrl(
        uuid: string,
        noteUuid: string,
        attachmentUuid: string,
    ): Promise<GetAttachmentDownloadUrlResponse> {
        return await _fetch(
            `/api/deals/${uuid}/notes/${noteUuid}/attachments/${attachmentUuid}/download-url`,
            { method: 'GET' },
            GetAttachmentDownloadUrlResponse,
        );
    }

    async function deleteNoteAttachment(uuid: string, noteUuid: string, attachmentUuid: string) {
        return await _fetch(
            `/api/deals/${uuid}/notes/${noteUuid}/attachments/${attachmentUuid}`,
            { method: 'DELETE' },
            z.object({}),
        );
    }

    return {
        getDealNotes,
        getPinnedDealNotes,
        postDealNotes,
        putNotePinned,
        patchDealNotes,
        deleteNote,
        getAtachmentUploadUrl,
        postAttachment,
        getAttachmentDownloadUrl,
        deleteNoteAttachment,
    };
}

function ReservationsRoutes() {
    async function getReservations(filters?: ReservationsFilters): Promise<ReservationsList> {
        let uri = '/api/reservations';
        if (filters) {
            uri += `?${objectToQueryString({
                ...filters,
            })}`;
        }
        return await _fetch(uri, { method: 'GET' }, ReservationsList);
    }

    return { getReservations };
}

function DealReservationRoutes() {
    async function getReservation(uuid: string): Promise<DealReservationResponse> {
        return await _fetch(`/api/deals/${uuid}/reservation`, { method: 'GET' }, DealReservationResponse);
    }

    async function postReservation(uuid: string, data: PostDealReservationRequest): Promise<DealReservationResponse> {
        return await _fetch(
            `/api/deals/${uuid}/reservation`,
            { method: 'POST', body: JSON.stringify(data) },
            DealReservationResponse,
        );
    }

    async function putReservation(
        uuid: string,
        reservationUuid: string,
        data: PostDealReservationRequest,
    ): Promise<DealReservationResponse> {
        return await _fetch(
            `/api/deals/${uuid}/reservation/${reservationUuid}`,
            { method: 'PUT', body: JSON.stringify(data) },
            DealReservationResponse,
        );
    }

    return {
        getReservation,
        postReservation,
        putReservation,
    };
}

function DealOffersRoutes() {
    async function getDealOffers(uuid: string): Promise<GetOffersResponse> {
        return await _fetch(`/api/deals/${uuid}/offers`, { method: 'GET' }, GetOffersResponse);
    }

    async function postDealOffer(uuid: string, data: PostOfferRequest): Promise<PostOfferResponse> {
        return await _fetch(
            `/api/deals/${uuid}/offers`,
            { method: 'POST', body: JSON.stringify(data) },
            PostOfferResponse,
        );
    }

    async function patchDealSelectedOffer(
        uuid: string,
        offerUuid: string,
        data: {
            isSelected: boolean;
        },
    ): Promise<GetOfferItemResponse> {
        return await _fetch(
            `/api/deals/${uuid}/offers/${offerUuid}`,
            { method: 'PATCH', body: JSON.stringify(data) },
            GetOfferItemResponse,
        );
    }

    return {
        getDealOffers,
        postDealOffer,
        patchDealSelectedOffer,
    };
}

function DealAmountBreakdownRoutes() {
    async function getDealVehicleAmountBreakdown(dealUuid: string): Promise<GetDealAmountBreakdown> {
        return await _fetch(`/api/deals/${dealUuid}/amount-breakdown`, { method: 'GET' }, GetDealAmountBreakdown);
    }

    async function getDealVehicleMetrics(dealUuid: string): Promise<DealAmountBreakdownMetrics> {
        return await _fetch(`/api/deals/${dealUuid}/c2c-metrics`, { method: 'GET' }, DealAmountBreakdownMetrics);
    }

    async function patchDealVehicleAmountBreakdown(
        dealUuid: string,
        data: PatchDealAmountBreakdown,
    ): Promise<PatchDealAmountBreakdown> {
        return await _fetch(
            `/api/deals/${dealUuid}/catalogue-vehicle`,
            { method: 'PATCH', body: JSON.stringify(data) },
            PatchDealAmountBreakdown,
        );
    }

    async function postDealVehicleAmountBreakdownTransaction(
        dealUuid: string,
        data: PutDealAmountBreakdownTransaction,
    ): Promise<DealTransaction> {
        return await _fetch(
            `/api/deals/${dealUuid}/purchase-transactions`,
            { method: 'POST', body: JSON.stringify(data) },
            DealTransaction,
        );
    }

    async function putDealVehicleAmountBreakdownTransaction(
        dealUuid: string,
        transactionUuid: string,
        data: PutDealAmountBreakdownTransaction,
    ): Promise<DealTransaction> {
        return await _fetch(
            `/api/deals/${dealUuid}/purchase-transactions/${transactionUuid}`,
            { method: 'PUT', body: JSON.stringify(data) },
            DealTransaction,
        );
    }

    async function postDealVehicleAmountBreakdownDiscount(
        dealUuid: string,
        data: PutDealAmountBreakdownDiscount,
    ): Promise<DealDiscount> {
        return await _fetch(
            `/api/deals/${dealUuid}/purchase-discounts`,
            { method: 'POST', body: JSON.stringify(data) },
            DealDiscount,
        );
    }

    async function putDealVehicleAmountBreakdownDiscount(
        dealUuid: string,
        discountUuid: string,
        data: PutDealAmountBreakdownDiscount,
    ): Promise<DealDiscount> {
        return await _fetch(
            `/api/deals/${dealUuid}/purchase-discounts/${discountUuid}`,
            { method: 'PUT', body: JSON.stringify(data) },
            DealDiscount,
        );
    }

    async function deleteDealVehicleAmountBreakdownDiscount(dealUuid: string, discountUuid: string) {
        return await _fetch(
            `/api/deals/${dealUuid}/purchase-discounts/${discountUuid}`,
            { method: 'DELETE' },
            z.object({}),
        );
    }

    return {
        getDealVehicleAmountBreakdown,
        getDealVehicleMetrics,
        patchDealVehicleAmountBreakdown,
        postDealVehicleAmountBreakdownTransaction,
        putDealVehicleAmountBreakdownTransaction,
        postDealVehicleAmountBreakdownDiscount,
        putDealVehicleAmountBreakdownDiscount,
        deleteDealVehicleAmountBreakdownDiscount,
    };
}

function DealSubmissionsDetailsRoutes() {
    async function getDealSubmissions(uuid: string) {
        return await _fetch(`/api/deals/${uuid}/submissions`, { method: 'GET' }, GetDealSubmissionList);
    }

    async function getSubmissionDetails(dealUuid: string, submissionUuid: string): Promise<GetSubmissionDetails> {
        return await _fetch(
            `/api/deals/${dealUuid}/submissions/${submissionUuid}`,
            { method: 'GET' },
            GetSubmissionDetails,
        );
    }

    async function patchSubmissionOfferDetails(
        dealUuid: string,
        submissionUuid: string,
        data: PatchSubmissionOfferRequest,
    ): Promise<GetSubmissionDetails> {
        return await _fetch(
            `/api/deals/${dealUuid}/submissions/${submissionUuid}`,
            { method: 'PATCH', body: JSON.stringify(data) },
            GetSubmissionDetails,
        );
    }

    async function getSubmissionTransitionHistory(
        dealUuid: string,
        submissionUuid: string,
    ): Promise<TransitionHistory> {
        return await _fetch(
            `/api/deals/${dealUuid}/submissions/${submissionUuid}/status-transitions`,
            { method: 'GET' },
            TransitionHistory,
        );
    }

    async function postSubmissionStatusChange(
        dealUuid: string,
        submissionUuid: string,
        data: ChangeSubmissionsStatusProps,
    ) {
        return await _fetch(
            `/api/deals/${dealUuid}/submissions/${submissionUuid}/status-transitions`,
            {
                method: 'POST',
                body: JSON.stringify({
                    ...data,
                }),
            },
            TransitionHistoryRecord,
        );
    }

    return {
        getDealSubmissions,
        getSubmissionDetails,
        patchSubmissionOfferDetails,
        postSubmissionStatusChange,
        getSubmissionTransitionHistory,
    };
}

function ClientRoutes() {
    async function getClients(filters?: ClientListFilters): Promise<ClientList> {
        let uri = '/api/clients';
        if (filters) {
            uri += `?${objectToQueryString({
                ...filters,
            })}`;
        }
        return await _fetch(uri, { method: 'GET' }, ClientList);
    }

    async function getClientDetails(uuid: string): Promise<ClientDetails> {
        return await _fetch(`/api/clients/${uuid}`, { method: 'GET' }, ClientDetails);
    }

    async function patchClientDetails(
        uuid: string,
        data: Partial<
            PersonalInformationFormData &
                BillingAddressFormData &
                EmploymentFormData &
                BankDetailsFormData &
                CoOwnerFormData &
                DocumentsFormData
        >,
    ): Promise<ClientDetails> {
        return await _fetch(`/api/clients/${uuid}`, { method: 'PATCH', body: JSON.stringify(data) }, ClientDetails);
    }

    async function postCreateClient(data: CreateClientFormData): Promise<ClientDetails> {
        return await _fetch(
            `/api/clients/create`,
            {
                method: 'POST',
                body: JSON.stringify(data),
            },
            ClientDetails,
        );
    }

    return {
        getClients,
        getClientDetails,
        patchClientDetails,
        postCreateClient,
    };
}

function RentingRoutes() {
    async function getRentTrackingDetails(uuid: string): Promise<RentTrackingDetails> {
        return await _fetch(`/api/deals/${uuid}/rent-tracking-details`, { method: 'GET' }, RentTrackingDetails);
    }

    async function putRentTrackingDetails(uuid: string, data: PutRentTrackingDetails): Promise<RentTrackingDetails> {
        return await _fetch(
            `/api/deals/${uuid}/rent-tracking-details`,
            { method: 'PUT', body: JSON.stringify(data) },
            RentTrackingDetails,
        );
    }

    async function getVehicleInspection(uuid: string): Promise<VehicleInspection> {
        return await _fetch(`/api/deals/${uuid}/vehicle-inspection`, { method: 'GET' }, VehicleInspection);
    }

    async function putVehicleInspection(uuid: string, data: VehicleInspection): Promise<VehicleInspection> {
        return await _fetch(
            `/api/deals/${uuid}/vehicle-inspection`,
            { method: 'PUT', body: JSON.stringify(data) },
            VehicleInspection,
        );
    }

    return {
        getRentTrackingDetails,
        putRentTrackingDetails,
        getVehicleInspection,
        putVehicleInspection,
    };
}

function DealInfoRoutes() {
    async function getDealPersonalDetail(uuid: string): Promise<GetDealPersonalDetailsResponse> {
        return await _fetch(
            `/api/deals/${uuid}/info/personal-details`,
            { method: 'GET' },
            GetDealPersonalDetailsResponse,
        );
    }

    // Deprecated
    async function getVehicleDetails(licensePlate: string): Promise<GetVehicleDetailsResponse> {
        return await _fetch(
            `/api/catalogue-vehicles/vehicle-details?licensePlate=${licensePlate}`,
            { method: 'GET' },
            GetVehicleDetailsResponse,
        );
    }

    async function putDealPersonalDetail(
        uuid: string,
        data: PutDealPersonalDetailsRequest,
    ): Promise<GetDealPersonalDetailsResponse> {
        return await _fetch(
            `/api/deals/${uuid}/info/personal-details`,
            { method: 'PUT', body: JSON.stringify(data) },
            GetDealPersonalDetailsResponse,
        );
    }

    async function getDealAddresses(uuid: string): Promise<GetDealAddressesResponse> {
        return await _fetch(`/api/deals/${uuid}/info/addresses`, { method: 'GET' }, GetDealAddressesResponse);
    }

    async function putDealAddresses(uuid: string, data: PutDealAddressesRequest): Promise<PutDealAddressesRequest> {
        return await _fetch(
            `/api/deals/${uuid}/info/addresses`,
            { method: 'PUT', body: JSON.stringify(data) },
            PutDealAddressesRequest,
        );
    }

    async function getDealEmployment(uuid: string): Promise<GetDealEmploymentResponse> {
        return await _fetch(`/api/deals/${uuid}/info/employment`, { method: 'GET' }, GetDealEmploymentResponse);
    }

    async function putDealEmployment(uuid: string, data: PutDealEmploymentRequest): Promise<GetDealEmploymentResponse> {
        return await _fetch(
            `/api/deals/${uuid}/info/employment`,
            { method: 'PUT', body: JSON.stringify(data) },
            GetDealEmploymentResponse,
        );
    }

    async function getDealVehicle(uuid: string): Promise<GetDealVehicleReponse> {
        return await _fetch(`/api/deals/${uuid}/info/vehicle`, { method: 'GET' }, GetDealVehicleReponse);
    }

    async function putDealVehicle(uuid: string, data: PutDealVehicleRequest): Promise<PutDealVehicleResponse> {
        return await _fetch(`/api/deals/${uuid}/info/vehicle`, { method: 'PUT', body: JSON.stringify(data) }, Vehicle);
    }

    async function getDealForeignDetail(uuid: string): Promise<GetDealForeignDetailResponse> {
        return await _fetch(`/api/deals/${uuid}/info/foreign-details`, { method: 'GET' }, GetDealForeignDetailResponse);
    }

    async function putDealForeignDetail(
        uuid: string,
        data: PutDealForeignDetailResponse,
    ): Promise<PutDealForeignDetailResponse> {
        return await _fetch(
            `/api/deals/${uuid}/info/foreign-details`,
            { method: 'PUT', body: JSON.stringify(data) },
            PutDealForeignDetailResponse,
        );
    }

    async function getDealMktDetail(uuid: string): Promise<GetMktDetailsResponse> {
        return await _fetch(`/api/deals/${uuid}/info/mkt-details`, { method: 'GET' }, GetMktDetailsResponse);
    }

    async function getDealBankDetail(dealUuid: string): Promise<GetDealBankDetailResponse> {
        return await _fetch(`/api/deals/${dealUuid}/info/bank-details`, { method: 'GET' }, GetDealBankDetailResponse);
    }

    async function putDealBankDetail(
        dealUuid: string,
        data: PutDealBankDetailRequest,
    ): Promise<GetDealBankDetailResponse> {
        return await _fetch(
            `/api/deals/${dealUuid}/info/bank-details`,
            { method: 'PUT', body: JSON.stringify(data) },
            GetDealBankDetailResponse,
        );
    }

    async function getDealCoownerDetail(dealUuid: string): Promise<GetDealCoownerDetailResponse> {
        return await _fetch(
            `/api/deals/${dealUuid}/info/co-owner-details`,
            { method: 'GET' },
            GetDealCoownerDetailResponse,
        );
    }

    async function putDealCoownerDetail(
        dealUuid: string,
        data: PutDealCoownerDetailRequest,
    ): Promise<GetDealCoownerDetailResponse> {
        return await _fetch(
            `/api/deals/${dealUuid}/info/co-owner-details`,
            { method: 'PUT', body: JSON.stringify(data) },
            GetDealCoownerDetailResponse,
        );
    }

    async function getDealClient(dealUuid: string) {
        return await _fetch(`/api/deals/${dealUuid}/client`, { method: 'GET' }, ClientDetails);
    }

    async function putDealClient(dealUuid: string, clientUuid: string) {
        return await _fetch(
            `/api/deals/${dealUuid}/client`,
            { method: 'PUT', body: JSON.stringify({ clientUuid: clientUuid }) },
            z.object({}),
        );
    }

    return {
        getDealPersonalDetail,
        getVehicleDetails,
        getDealAddresses,
        putDealAddresses,
        getDealBankDetail,
        putDealBankDetail,
        getDealEmployment,
        putDealEmployment,
        getDealCoownerDetail,
        putDealCoownerDetail,
        getDealVehicle,
        putDealVehicle,
        getDealForeignDetail,
        putDealForeignDetail,
        getDealMktDetail,
        putDealPersonalDetail,
        getDealClient,
        putDealClient,
    };
}

function VehicleRoutes() {
    async function postCreateCatalogueVehicle(data: PostCatalogueVehicle): Promise<GetCatalogueVehicleDetails> {
        return await _fetch(
            `/api/catalogue-vehicles/create`,
            {
                method: 'POST',
                body: JSON.stringify(data),
            },
            GetCatalogueVehicleDetails,
        );
    }

    async function getCatalogueVehicles(
        filters?: Partial<CatalogueVehicleFilters>,
    ): Promise<GetCatalogueVehiclesListResponse> {
        let uri = '/api/catalogue-vehicles';
        if (filters) {
            uri += `?${objectToQueryString(filters)}`;
        }
        return await _fetch(uri, { method: 'GET' }, GetCatalogueVehiclesListResponse);
    }

    async function getCatalogueVehiclesCsv(filters?: Partial<CatalogueVehicleFilters>): Promise<any> {
        let uri = '/api/catalogue-vehicles';
        if (filters) {
            uri += `?${objectToQueryString(filters)}`;
        }
        return await _fetch(
            uri,
            {
                method: 'GET',
                headers: {
                    accept: 'text/csv',
                },
            },
            z.unknown(),
            { returnRawResponse: true },
        );
    }

    async function getCatalogueVehicleDetailsByLicensePlate(
        licensePlate?: string | null,
    ): Promise<GetCatalogueVehicleDetails> {
        return await _fetch(
            `/api/catalogue-vehicles/vehicle-details/${licensePlate}`,
            { method: 'GET' },
            GetCatalogueVehicleDetails,
        );
    }

    async function getCatalogueVehicleDetails(uuid: string): Promise<GetCatalogueVehicleDetails> {
        return await _fetch(
            `/api/catalogue-vehicles/${uuid}/vehicle-details`,
            { method: 'GET' },
            GetCatalogueVehicleDetails,
        );
    }

    async function putCatalogueVehicleDetails(
        uuid: string,
        data: PutCatalogueVehicleDetails,
    ): Promise<GetCatalogueVehicleDetails> {
        return await _fetch(
            `/api/catalogue-vehicles/${uuid}/vehicle-details`,
            { method: 'PUT', body: JSON.stringify(data) },
            GetCatalogueVehicleDetails,
        );
    }

    async function postCatalogueVehicleStatusChange(uuid: string, data: ChangeCatalogueVehicleStatusProps) {
        return await _fetch(
            `/api/catalogue-vehicles/${uuid}/status-transitions`,
            {
                method: 'POST',
                body: JSON.stringify({
                    ...data,
                }),
            },
            CatalogueVehicleTransitionHistoryRecord,
        );
    }

    async function putCatalogueVehicleAssignedAgents(uuid: string, data: PutDealAssignedAgentRequest) {
        return await _fetch(
            `/api/catalogue-vehicles/${uuid}/agents`,
            { method: 'PUT', body: JSON.stringify(data) },
            PutDealAssignedAgentResponse,
        );
    }

    async function postCatalogueVehicleManagedAd(uuid: string, data: PostCatalogueVehicleManagedAd) {
        return await _fetch(
            `/api/catalogue-vehicles/${uuid}/managed-ad`,
            {
                method: 'POST',
                body: JSON.stringify({
                    ...data,
                }),
            },
            CatalogueVehicleManagedAd,
        );
    }

    async function putCatalogueVehicleManagedAd(
        uuid: string,
        managedAdUuid: string,
        data: PostCatalogueVehicleManagedAd,
    ) {
        return await _fetch(
            `/api/catalogue-vehicles/${uuid}/managed-ad/${managedAdUuid}`,
            {
                method: 'PUT',
                body: JSON.stringify({
                    ...data,
                }),
            },
            CatalogueVehicleManagedAd,
        );
    }

    async function getCatalogueVehicleNotes(
        catalogueVehicleUuid: string,
        filters?: NoteFilters,
    ): Promise<GetGenericNotesResponse> {
        let uri = `/api/catalogue-vehicles/${catalogueVehicleUuid}/notes`;
        uri += filters ? `?${objectToQueryString(filters)}` : '';
        return await _fetch(uri, { method: 'GET' }, GetGenericNotesResponse);
    }

    async function postCatalogueVehicleNotes(uuid: string, data: PostGenericNoteRequest): Promise<GenericNote> {
        return await _fetch(
            `/api/catalogue-vehicles/${uuid}/note`,
            { method: 'POST', body: JSON.stringify(data) },
            GenericNote,
        );
    }

    async function getCatalogueVehicleRepricing(uuid: string) {
        return await _fetch(
            `/api/catalogue-vehicles/${uuid}/repricing`,
            { method: 'GET' },
            GetCatalogueVehicleRepricing,
        );
    }

    async function postCatalogueVehicleRepricing(uuid: string, data: PostCatalogueVehicleRepricing) {
        return await _fetch(
            `/api/catalogue-vehicles/${uuid}/repricing`,
            { method: 'POST', body: JSON.stringify(data) },
            CatalogueVehicleRepricing,
        );
    }

    async function getCatalogueVehicleStatusTransitions(uuid: string): Promise<CatalogueVehicleTransitionHistory> {
        return await _fetch(
            `/api/catalogue-vehicles/${uuid}/status-transitions`,
            { method: 'GET' },
            CatalogueVehicleTransitionHistory,
        );
    }

    async function getCatalogueVehiclePublicationDescription(
        uuid: string,
    ): Promise<GetCatalogueVehiclePublicationDetails> {
        return await _fetch(
            `/api/catalogue-vehicles/${uuid}/publication-details`,
            { method: 'GET' },
            GetCatalogueVehiclePublicationDetails,
        );
    }

    async function postBulkVehicleCreationCsv(csv: File): Promise<BulkUpdateCsvSuccessesResponse> {
        const formData = new FormData();
        formData.append('bulkCreationCSV', csv, csv.name);

        return await _fetch(
            `/api/catalogue-vehicles/bulk-create`,
            {
                method: 'POST',
                body: formData,
            },
            BulkUpdateCsvSuccessesResponse,
        );
    }

    async function postBulkVehicleUpdateCsv(csv: File): Promise<BulkUpdateCsvUpdatesResponse> {
        const formData = new FormData();
        formData.append('bulkUpdateCSV', csv, csv.name);

        return await _fetch(
            `/api/catalogue-vehicles/bulk-price-update`,
            {
                method: 'POST',
                body: formData,
            },
            BulkUpdateCsvUpdatesResponse,
        );
    }

    async function getVehicleClientDetails(uuid: string): Promise<ClientDetails> {
        return await _fetch(`/api/catalogue-vehicles/${uuid}/client`, { method: 'GET' }, ClientDetails);
    }

    async function putVehicleClient(uuid: string, clientUuid: string) {
        return await _fetch(
            `/api/catalogue-vehicles/${uuid}/client`,
            { method: 'PUT', body: JSON.stringify({ clientUuid: clientUuid }) },
            z.object({}),
        );
    }

    async function getVehicleNotificationHistory(
        filters?: Partial<NotificationHistoryFilters>,
    ): Promise<GetNotificationHistoryListResponse> {
        let uri = '/api/catalogue-vehicle-notification-history';
        if (filters) {
            uri += `?${objectToQueryString(filters)}`;
        }
        return await _fetch(uri, { method: 'GET' }, GetNotificationHistoryListResponse);
    }

    async function getVehicleNotificationHistoryCsv(filters?: Partial<NotificationHistoryFilters>): Promise<any> {
        let uri = '/api/catalogue-vehicle-notification-history';
        if (filters) {
            uri += `?${objectToQueryString(filters)}`;
        }
        return await _fetch(
            uri,
            {
                method: 'GET',
                headers: {
                    accept: 'text/csv',
                },
            },
            z.unknown(),
            { returnRawResponse: true },
        );
    }

    return {
        postCreateCatalogueVehicle,
        getCatalogueVehicles,
        getCatalogueVehiclesCsv,
        getCatalogueVehicleDetailsByLicensePlate,
        getCatalogueVehicleDetails,
        putCatalogueVehicleDetails,
        postCatalogueVehicleStatusChange,
        putCatalogueVehicleAssignedAgents,
        postCatalogueVehicleManagedAd,
        putCatalogueVehicleManagedAd,
        getCatalogueVehicleNotes,
        postCatalogueVehicleNotes,
        getCatalogueVehicleRepricing,
        postCatalogueVehicleRepricing,
        getCatalogueVehicleStatusTransitions,
        getCatalogueVehiclePublicationDescription,
        postBulkVehicleCreationCsv,
        postBulkVehicleUpdateCsv,
        getVehicleClientDetails,
        putVehicleClient,
        getVehicleNotificationHistory,
        getVehicleNotificationHistoryCsv,
    };
}

function VehicleInfoRoutes() {
    async function getVehicleBasicInfo(uuid: string): Promise<GetVehicleBasicInfoResponse> {
        return await _fetch(`/api/vehicles/${uuid}/basic-info`, { method: 'GET' }, GetVehicleBasicInfoResponse);
    }

    async function getVehiclePricing(uuid: string): Promise<GetVehiclePriceResponse> {
        return await _fetch(`/api/vehicles/${uuid}/princing`, { method: 'GET' }, GetVehiclePriceResponse);
    }

    async function getVehicleExtrasInfo(uuid: string): Promise<GetVehicleExtrasResponse> {
        return await _fetch(`/api/vehicles/${uuid}/extras`, { method: 'GET' }, GetVehicleExtrasResponse);
    }

    async function getVehiclePerformance(uuid: string): Promise<GetVehiclePerformanceResponse> {
        return await _fetch(`/api/vehicles/${uuid}/performance`, { method: 'GET' }, GetVehiclePerformanceResponse);
    }

    async function getVehicleStatus(uuid: string): Promise<GetVehicleStatusResponse> {
        return await _fetch(`/api/vehicles/${uuid}/status`, { method: 'GET' }, GetVehicleStatusResponse);
    }

    async function getVehicleMissingFieldsToPublish(uuid: string): Promise<string[]> {
        return await _fetch(
            `/api/vehicles/${uuid}/missing-fields-to-publish`,
            { method: 'GET' },
            CatalogueVehicleMissingFieldsToPublishResponse,
        );
    }

    return {
        getVehiclePricing,
        getVehicleBasicInfo,
        getVehicleExtrasInfo,
        getVehiclePerformance,
        getVehicleStatus,
        getVehicleMissingFieldsToPublish,
    };
}

function VehicleFeaturesRoutes() {
    async function getVehicleColors(max?: number): Promise<GetVehicleFeatureResponse> {
        let uri = '/api/vehicles/features/colors';
        if (max) {
            uri = uri + `?max=${max}`;
        }
        return await _fetch(uri, { method: 'GET' }, GetVehicleFeatureResponse);
    }

    async function getVehicleMakes(): Promise<GetVehicleFeatureResponse> {
        const uri = `/api/vehicles/features/makes?${objectToQueryString({
            max: 100,
        })}`;

        return await _fetch(uri, { method: 'GET' }, GetVehicleFeatureResponse);
    }

    async function getVehicleModels(filters?: { makeUuid: string }): Promise<GetVehicleFeatureResponse> {
        let uri = '/api/vehicles/features/models';
        if (filters) {
            uri += `?${objectToQueryString(filters)}`;
        }

        return await _fetch(uri, { method: 'GET' }, GetVehicleFeatureResponse);
    }

    async function postVehicleModels(data: PostVeicleModelFeature): Promise<PostFeatureResponse> {
        return await _fetch(
            `/api/vehicles/features/post-models`,
            {
                method: 'POST',
                body: JSON.stringify(data),
            },
            PostFeatureResponse,
        );
    }

    async function getVehicleVersions(filters?: { modelUuid: string }): Promise<GetVehicleFeatureResponse> {
        let uri = '/api/vehicles/features/versions';
        if (filters) {
            uri += `?${objectToQueryString(filters)}`;
        }
        return await _fetch(uri, { method: 'GET' }, GetVehicleFeatureResponse);
    }

    async function postVehicleVersions(data: PostVeicleVersionFeature): Promise<PostFeatureResponse> {
        return await _fetch(
            `/api/vehicles/features/post-versions`,
            {
                method: 'POST',
                body: JSON.stringify(data),
            },
            PostFeatureResponse,
        );
    }

    async function getVehicleYears(filters?: { versionUuid: string }): Promise<GetVehicleFeatureResponse> {
        let uri = '/api/vehicles/features/years';
        if (filters) {
            uri += `?${objectToQueryString(filters)}`;
        }
        return await _fetch(uri, { method: 'GET' }, GetVehicleFeatureResponse);
    }

    async function getVehicleExtras(): Promise<GetVehicleFeatureResponse> {
        const uri = `/api/vehicles/features/extras?${objectToQueryString({
            max: 100,
        })}`;

        return await _fetch(uri, { method: 'GET' }, GetVehicleFeatureResponse);
    }

    return {
        getVehicleColors,
        getVehicleMakes,
        getVehicleModels,
        postVehicleModels,
        getVehicleVersions,
        postVehicleVersions,
        getVehicleYears,
        getVehicleExtras,
    };
}

function ConfigurationsRoutes() {
    async function getConfigurationById(id: string): Promise<GetConfigurationResponse> {
        return await _fetch(`/api/configurations/${id}`, { method: 'GET' }, GetConfigurationResponse);
    }

    async function putConfiguration(id: string, data: PutConfigurationRequest): Promise<GetConfigurationResponse> {
        return await _fetch(
            `/api/configurations/${id}`,
            { method: 'PUT', body: JSON.stringify(data) },
            GetConfigurationResponse,
        );
    }

    return {
        getConfigurationById,
        putConfiguration,
    };
}

function UsersRoutes() {
    async function getUsers(filters?: GetUserFilters): Promise<GetUserResponse> {
        let uri = '/api/users';
        if (filters) {
            uri += `?${objectToQueryString(filters)}`;
        }

        return await _fetch(uri, { method: 'GET' }, GetUserResponse);
    }

    async function patchUserByUuid(userUuid: string, data: PatchUserRequest): Promise<User> {
        return await _fetch(`/api/users/${userUuid}`, { method: 'PATCH', body: JSON.stringify(data) }, User);
    }

    return {
        getUsers,
        patchUserByUuid,
    };
}

function DecisionScienceRoutes() {
    async function c2cAgreedPricesCalculator(
        purchasePrice?: number,
        cashPrice?: number,
    ): Promise<CalculatedAgreedPrice> {
        const query = cashPrice ? `cash_price=${cashPrice}` : `purchase_price=${purchasePrice}`;

        const uri = `/api/decision-science/v1/finance/c2c-agreed-prices?${query}`;

        return await _fetch(uri, { method: 'GET' }, CalculatedAgreedPrice);
    }

    return {
        c2cAgreedPricesCalculator,
    };
}

export function createAPIClient() {
    async function getStatistics(filters?: Partial<StatisticsFilters>): Promise<GetStatisticsResponse> {
        let uri = '/api/statistics';
        if (filters) {
            uri += `?${objectToQueryString(filters)}`;
        }
        return await _fetch(uri, { method: 'GET' }, GetStatisticsResponse);
    }

    async function getOperations(filters?: Partial<OperationFilters>): Promise<GetOperationsListResponse> {
        let uri = '/api/operations';
        if (filters) {
            uri += `?${objectToQueryString(filters)}`;
        }
        return await _fetch(uri, { method: 'GET' }, GetOperationsListResponse);
    }

    async function getProvinces(): Promise<GetProvinceResponse> {
        return await _fetch('/api/provinces', { method: 'GET' }, GetProvinceResponse);
    }

    async function getRespondChat(filters: ChatListFilters): Promise<Paginated<ChatMessage>> {
        let uri = '/api/respondio/messages';
        if (filters) {
            uri += `?${objectToQueryString({
                ...filters,
            })}`;
        }
        return await _fetch(uri, { method: 'GET' }, PaginatedSchema(ChatMessage));
    }

    return {
        ...DealsRoutes(),
        ...DealOffersRoutes(),
        ...DealSubmissionsDetailsRoutes(),
        ...DealInfoRoutes(),
        ...DealNotesRoutes(),
        ...VehicleRoutes(),
        ...VehicleInfoRoutes(),
        ...VehicleFeaturesRoutes(),
        ...ClientRoutes(),
        ...RentingRoutes(),
        ...ReservationsRoutes(),
        ...DealReservationRoutes(),
        ...DealAmountBreakdownRoutes(),
        ...ConfigurationsRoutes(),
        ...UsersRoutes(),
        ...DecisionScienceRoutes(),
        getStatistics,
        getOperations,
        getProvinces,
        getRespondChat,
    };
}
